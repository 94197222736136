import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import cx from 'classnames';
import { useReCaptcha } from 'next-recaptcha-v3';
import PropTypes from 'prop-types';

import api from 'services/api';

import ButtonCTA from 'components/ui/ButtonCTA';
import Grid from 'components/ui/Grid';
import PlaceOfPurchase from 'components/ui/PlaceOfPurchase';
import ReCaptchaLegal from 'components/ui/ReCaptchaLegal';
import Text from 'components/ui/Text';

import { EMAIL_REGEX } from 'utils';

import AddressLookup from './AddressLookup';
import RadioGroup from './RadioGroup';

import ContactContext from '../Context';
import { FEEDBACK_TYPES, MARKETING_LABELS } from '../constants';

import styles from './Form.module.scss';

const formStates = Object.freeze({
    SUCCESS: 'SUCCESS',
    INITIAL: 'INITIAL',
    REQUESTING: 'REQUESTING',
    ERROR: 'ERROR',
    ERROR_BAD_RECAPTCHA: 'ERROR_BAD_RECAPTCHA',
});

const DEFAULT = 'DEFAULT';

const Form = ({
    headline,
    marketingLegalText = 'Send me the latest on Tillamook special releases, discounts, recipes and limited time offerings.',
    defaultMessage,
    cta,
    onSuccess,
    formState: contactState,
}) => {
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            best_by_date: DEFAULT,
            plant_code: DEFAULT,
            product_sku: DEFAULT,
            reason: DEFAULT,
            first_time_buyer: DEFAULT,
            time: '',
            item_number: '',
            flavor: '',
        },
    });

    const [formState, setFormState] = useState(formStates.INITIAL);
    const [, setFormData] = useState({});
    const { retailers } = useContext(ContactContext);

    const selectValues = watch(['state']); // you can also target specific fields by their names

    const watchedValues = {
        state: 0,
    };

    const handleAddressRetrieved = useCallback(
        addressLookup => {
            console.log(addressLookup);
            const { line_1, line_2, city, state_abbreviation, zip_code } =
                addressLookup;
            setValue('address', [line_1, line_2].join(''));
            setValue('city', city);
            setValue('state', state_abbreviation);
            setValue('zip', zip_code);
        },
        [setValue]
    );

    useEffect(() => {
        const plantCodes =
            contactState?.size?.packagingCodeExample?.codeInformation
                ?.plantCodes;

        if (contactState?.productCode) {
            // ^ passed through ProductCode form and pressed submit
            const { bestByDay, bestByMonth, bestByYear, time, plantCode } =
                contactState.productCode;

            [bestByDay, bestByMonth, bestByYear].every(v => v !== DEFAULT) &&
                setValue(
                    'best_by_date',
                    `${bestByMonth}/${bestByDay}/${bestByYear}`
                );
            time && setValue('time', time);
            plantCode && setValue('plant_code', plantCode);
        }

        plantCodes?.length === 1 && setValue('plant_code', plantCodes[0]);
        // ^ force-set plant_code if there is only one plant code option available

        contactState?.contactReason &&
            setValue('reason', contactState.contactReason);

        contactState?.product?.category?.title &&
            setValue('product1', contactState?.product?.category?.title);

        contactState?.product?.displayName &&
            setValue('product2', contactState?.product?.displayName);

        const [key, options] =
            Object.entries(
                contactState?.product?.category?.attributes || {}
            ).find(([, value]) => value?.primary) || [];

        const valueId = contactState?.product?.attributes[key];

        const value = options?.options?.find(
            item => item.id === parseInt(valueId, 10)
        );

        setValue('product3', value?.label || 'none');

        contactState?.size?.size &&
            setValue('product4', contactState.size.size);

        contactState?.size?.flavor &&
            setValue('flavor', contactState.size.flavor);

        contactState?.size?.itemNumber &&
            setValue('item_number', contactState.size.itemNumber);

        contactState?.sku && setValue('product5', contactState.sku);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactState]);

    const { executeRecaptcha } = useReCaptcha();

    const isRequesting = formState === formStates.REQUESTING;
    const isError = formState === formStates.ERROR;
    const isRecaptchaError = formState === formStates.ERROR_BAD_RECAPTCHA;
    const isSuccess = formState === formStates.SUCCESS;

    const isProductFeedback =
        contactState?.contactReason === FEEDBACK_TYPES.COMPLAINT;

    useEffect(() => {
        isSuccess && onSuccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);

    const onSubmit = async formData => {
        if (isRequesting) {
            return;
        }

        setFormState(formStates.REQUESTING);

        if (!executeRecaptcha) {
            setFormState(formStates.ERROR);
            return;
        }

        const token = await executeRecaptcha('contact_page');

        if (!token) {
            setFormState(formStates.ERROR);
            return;
        }

        const formDataClean = Object.keys(formData).reduce((obj, key) => {
            if (obj[key] === DEFAULT) {
                delete obj[key];
            }
            return obj;
        }, formData);

        try {
            // Required to set cookie & track browsing
            await window.klaviyo.identify({
                $email: formDataClean.email_address,
            });
        } catch (e) {}

        try {
            const { data } = await api.contact.send({
                ...formDataClean,
                token: token,
            });

            if (data.success) {
                setFormData(formDataClean);
                setFormState(formStates.SUCCESS);
                reset();
            } else {
                setFormState(formStates.ERROR);
            }
        } catch (e) {
            if (e.response?.data?.message) {
                switch (e.response?.data?.message) {
                    case 'ERROR_BAD_RECAPTCHA':
                        setFormState(formStates.ERROR_BAD_RECAPTCHA);
                        break;
                    default:
                        setFormState(formStates.ERROR);
                        break;
                }
            } else {
                setFormState(formStates.ERROR);
            }
        }
    };

    return (
        <section className={styles.contact}>
            <Grid>
                <div className={styles.contactInner}>
                    {cta && cta()}

                    <Text
                        as="h2"
                        baseTheme="headingLarge"
                        className={styles.headline}
                    >
                        {headline}
                    </Text>
                    <form
                        className={styles.contactForm}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div className={styles.nameBlock}>
                            <div className={styles.inputWrapper}>
                                <Text
                                    htmlFor="first_name"
                                    as="label"
                                    baseTheme="labelSmall"
                                >
                                    First Name*
                                </Text>
                                <Text baseTheme="headingXSmall">
                                    <input
                                        id="first_name"
                                        {...register('first_name', {
                                            required: true,
                                        })}
                                        type="text"
                                        placeholder="John"
                                        className={cx(styles.input, {
                                            [styles.hasError]:
                                                errors.first_name,
                                        })}
                                    />
                                </Text>
                                <div className={styles.error}>
                                    {errors.first_name && (
                                        <Text baseTheme="bodyXSmall">
                                            Please fill out this field
                                        </Text>
                                    )}
                                </div>
                            </div>
                            <div className={styles.inputWrapper}>
                                <Text
                                    htmlFor="last_name"
                                    as="label"
                                    baseTheme="labelSmall"
                                >
                                    Last Name*
                                </Text>
                                <Text baseTheme="headingXSmall">
                                    <input
                                        id="last_name"
                                        {...register('last_name', {
                                            required: true,
                                        })}
                                        type="text"
                                        placeholder="Tillamook"
                                        className={cx(styles.input, {
                                            [styles.hasError]: errors.last_name,
                                        })}
                                    />
                                </Text>
                                <div className={styles.error}>
                                    {errors.last_name && (
                                        <Text baseTheme="bodyXSmall">
                                            Please fill out this field
                                        </Text>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={styles.inputWrapper}>
                            <Text
                                htmlFor="email_address"
                                as="label"
                                baseTheme="labelSmall"
                            >
                                Email Address*
                            </Text>
                            <Text baseTheme="headingXSmall">
                                <input
                                    id="email_address"
                                    {...register('email_address', {
                                        required: true,
                                        pattern: EMAIL_REGEX,
                                    })}
                                    type="email"
                                    placeholder="John@tillamook.com"
                                    className={cx(styles.input, {
                                        [styles.hasError]: errors.email_address,
                                    })}
                                />
                            </Text>
                            <div className={styles.error}>
                                {errors.email_address && (
                                    <Text baseTheme="bodyXSmall">
                                        Please enter a valid email address
                                    </Text>
                                )}
                            </div>
                        </div>
                        <div className={styles.inputWrapper}>
                            <Text
                                htmlFor="phone"
                                as="label"
                                baseTheme="labelSmall"
                            >
                                Phone Number
                            </Text>
                            <Text baseTheme="headingXSmall">
                                <input
                                    id="phone"
                                    {...register('phone')}
                                    type="tel"
                                    placeholder="(555) 555-5555"
                                    className={cx(styles.input, {
                                        [styles.hasError]: errors.phone,
                                    })}
                                />
                            </Text>
                            <div className={styles.error}>
                                {errors.phone && (
                                    <Text baseTheme="bodyXSmall">
                                        Please fill out this field
                                    </Text>
                                )}
                            </div>
                        </div>

                        <div className={styles.inputWrapper}>
                            <Text
                                htmlFor="address"
                                as="label"
                                baseTheme="labelSmall"
                            >
                                {`Address${isProductFeedback ? '*' : ''}`}
                            </Text>

                            <Text baseTheme="headingXSmall">
                                <AddressLookup
                                    onAddressRetrieved={handleAddressRetrieved}
                                    required={isProductFeedback}
                                    setValue={address => {
                                        setValue('address', address);
                                    }}
                                    className={cx(styles.input, {
                                        [styles.hasError]: errors.address,
                                    })}
                                />
                            </Text>
                            <div className={styles.error}>
                                {errors.address && (
                                    <Text baseTheme="bodyXSmall">
                                        Please fill out this field
                                    </Text>
                                )}
                            </div>
                        </div>

                        <div className={styles.addressBlock}>
                            <div className={styles.inputWrapper}>
                                <Text
                                    htmlFor="city"
                                    as="label"
                                    baseTheme="labelSmall"
                                >
                                    {`City${isProductFeedback ? '*' : ''}`}
                                </Text>
                                <Text baseTheme="headingXSmall">
                                    <input
                                        id="city"
                                        {...register('city', {
                                            required: isProductFeedback,
                                        })}
                                        type="text"
                                        placeholder="Tillamook"
                                        className={styles.input}
                                    />
                                </Text>
                                <div className={styles.error}>
                                    {errors.city && (
                                        <Text baseTheme="bodyXSmall">
                                            Please fill out this field
                                        </Text>
                                    )}
                                </div>
                            </div>
                            <div className={styles.inputWrapper}>
                                <Text
                                    htmlFor="state"
                                    as="label"
                                    baseTheme="labelSmall"
                                >
                                    {`State*`}
                                </Text>
                                <Text baseTheme="headingXSmall">
                                    <select
                                        {...register('state', {
                                            required: true,
                                            validate: value =>
                                                value !== DEFAULT,
                                        })}
                                        id="state"
                                        defaultValue={DEFAULT}
                                        className={cx(styles.select, {
                                            [styles.hasError]: errors.state,
                                            [styles.isDefault]:
                                                selectValues[
                                                    watchedValues.state
                                                ] === DEFAULT,
                                        })}
                                    >
                                        <option value={DEFAULT} disabled hidden>
                                            State
                                        </option>
                                        <option value="AL">Alabama</option>
                                        <option value="AK">Alaska</option>
                                        <option value="AZ">Arizona</option>
                                        <option value="AR">Arkansas</option>
                                        <option value="CA">California</option>
                                        <option value="CO">Colorado</option>
                                        <option value="CT">Connecticut</option>
                                        <option value="DE">Delaware</option>
                                        <option value="DC">
                                            District Of Columbia
                                        </option>
                                        <option value="FL">Florida</option>
                                        <option value="GA">Georgia</option>
                                        <option value="HI">Hawaii</option>
                                        <option value="ID">Idaho</option>
                                        <option value="IL">Illinois</option>
                                        <option value="IN">Indiana</option>
                                        <option value="IA">Iowa</option>
                                        <option value="KS">Kansas</option>
                                        <option value="KY">Kentucky</option>
                                        <option value="LA">Louisiana</option>
                                        <option value="ME">Maine</option>
                                        <option value="MD">Maryland</option>
                                        <option value="MA">
                                            Massachusetts
                                        </option>
                                        <option value="MI">Michigan</option>
                                        <option value="MN">Minnesota</option>
                                        <option value="MS">Mississippi</option>
                                        <option value="MO">Missouri</option>
                                        <option value="MT">Montana</option>
                                        <option value="NE">Nebraska</option>
                                        <option value="NV">Nevada</option>
                                        <option value="NH">
                                            New Hampshire
                                        </option>
                                        <option value="NJ">New Jersey</option>
                                        <option value="NM">New Mexico</option>
                                        <option value="NY">New York</option>
                                        <option value="NC">
                                            North Carolina
                                        </option>
                                        <option value="ND">North Dakota</option>
                                        <option value="OH">Ohio</option>
                                        <option value="OK">Oklahoma</option>
                                        <option value="OR">Oregon</option>
                                        <option value="PA">Pennsylvania</option>
                                        <option value="RI">Rhode Island</option>
                                        <option value="SC">
                                            South Carolina
                                        </option>
                                        <option value="SD">South Dakota</option>
                                        <option value="TN">Tennessee</option>
                                        <option value="TX">Texas</option>
                                        <option value="UT">Utah</option>
                                        <option value="VT">Vermont</option>
                                        <option value="VA">Virginia</option>
                                        <option value="WA">Washington</option>
                                        <option value="WV">
                                            West Virginia
                                        </option>
                                        <option value="WI">Wisconsin</option>
                                        <option value="WY">Wyoming</option>
                                    </select>
                                </Text>
                                <div className={styles.error}>
                                    {errors.state && (
                                        <Text baseTheme="bodyXSmall">
                                            Please fill out this field
                                        </Text>
                                    )}
                                </div>
                            </div>
                            <div className={styles.inputWrapper}>
                                <Text
                                    htmlFor="zip"
                                    as="label"
                                    baseTheme="labelSmall"
                                >
                                    {`Zip Code*`}
                                </Text>

                                <Text baseTheme="headingXSmall">
                                    <input
                                        id="zip"
                                        {...register('zip', {
                                            required: true,
                                            pattern:
                                                /(^\d{5}$)|(^\d{5}-\d{4}$)/,
                                        })}
                                        type="text"
                                        pattern="[0-9]*"
                                        placeholder="97141"
                                        className={cx(styles.input, {
                                            [styles.hasError]: errors.zip,
                                        })}
                                    />
                                </Text>
                                <div className={styles.error}>
                                    {errors.zip && (
                                        <Text baseTheme="bodyXSmall">
                                            Invalid Zip Code
                                        </Text>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className={styles.inputWrapper}>
                            <Text
                                htmlFor="message"
                                as="label"
                                baseTheme="labelSmall"
                            >
                                Your Message*
                            </Text>
                            <Text baseTheme="headingXSmall">
                                <textarea
                                    className={cx(styles.message, {
                                        [styles.hasError]: errors.message,
                                    })}
                                    id="message"
                                    {...register('message', {
                                        required: true,
                                    })}
                                    placeholder={defaultMessage || ''}
                                    rows="15"
                                    cols="30"
                                />
                            </Text>
                            <div className={styles.error}>
                                {errors.message && (
                                    <Text baseTheme="bodyXSmall">
                                        Please enter a message
                                    </Text>
                                )}
                            </div>
                        </div>

                        {MARKETING_LABELS.WHERE_PURCHASED?.[
                            contactState?.contactReason
                        ] && (
                            <div className={styles.inputWrapper}>
                                <Text
                                    htmlFor="message"
                                    as="label"
                                    baseTheme="labelSmall"
                                >
                                    {
                                        MARKETING_LABELS.WHERE_PURCHASED?.[
                                            contactState.contactReason
                                        ]
                                    }
                                </Text>
                                <Text baseTheme="headingXSmall">
                                    <PlaceOfPurchase
                                        register={register}
                                        watch={watch}
                                        fieldName="where_purchased"
                                        setValue={setValue}
                                        retailers={retailers}
                                    />
                                </Text>
                            </div>
                        )}

                        {MARKETING_LABELS.FIRST_TIME_BUYER?.[
                            contactState?.contactReason
                        ] && (
                            <div
                                className={cx(
                                    styles.inputWrapper,
                                    styles.inlineRadioGroup
                                )}
                            >
                                <Text
                                    htmlFor="first_time_buyer"
                                    as="label"
                                    baseTheme="labelSmall"
                                >
                                    {
                                        MARKETING_LABELS.FIRST_TIME_BUYER?.[
                                            contactState?.contactReason
                                        ]
                                    }
                                </Text>
                                <RadioGroup
                                    register={register}
                                    fieldName="first_time_buyer"
                                    optionList={['Yes', 'No']}
                                    setValue={setValue}
                                    watch={watch}
                                />
                            </div>
                        )}

                        <Text baseTheme="bodySmall" className={styles.checkbox}>
                            <label className={styles.checkLabel}>
                                {marketingLegalText}
                                <input
                                    defaultChecked={!isProductFeedback}
                                    {...register('marketing')}
                                    type="checkbox"
                                />
                                <span className={styles.checkmark} />
                            </label>
                        </Text>

                        <div className={styles.submitRow}>
                            <ButtonCTA
                                className={styles.button}
                                text="Submit"
                                style="filled-blue-ocean"
                                type="submit"
                                onClick={handleSubmit}
                                disabled={isRequesting}
                            />
                            <Text as="span" baseTheme="bodySmall">
                                *Indicates required field
                            </Text>
                        </div>

                        {isError && (
                            <div className={styles.errorMessage}>
                                There was an error sending your request.
                            </div>
                        )}
                        {isRecaptchaError && (
                            <div className={styles.errorMessage}>
                                There was an issue validating your request.
                            </div>
                        )}
                        <ReCaptchaLegal />
                    </form>
                </div>
            </Grid>
        </section>
    );
};

Form.propTypes = {
    card: PropTypes.object,
    cta: PropTypes.func,
    defaultMessage: PropTypes.string,
    formState: PropTypes.object,
    headline: PropTypes.string,
    marketingLegalText: PropTypes.string,
    productData: PropTypes.object,
    onSuccess: PropTypes.func,
};

const BlockContactFormReCaptcha = props => <Form {...props} />;

export default BlockContactFormReCaptcha;
