import React, { useCallback, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import styles from './AddressLookup.module.scss';

const query = async (query, signal) => {
    const response = await fetch(
        `https://api.addresszen.com/v1/autocomplete/addresses?query=${query}&api_key=ak_m0yjsk0tinsf9Vw0oXvPcK02APYMa&set=insular&context=USA`,
        { signal }
    ).then(response => response.json());
    return response?.result?.hits;
};

const AddressLookup = ({ setValue, className, onAddressRetrieved }) => {
    const [address, setAddress] = useState('');
    const [hits, setHits] = useState([]);
    const [selected, setSelected] = useState(false);
    const [focused, setFocused] = useState(false);

    const abortControllerRef = useRef();
    const listRef = useRef();
    const rootRef = useRef();
    const inputRef = useRef();

    const handleSelected = useCallback(
        id => async () => {
            const response = await fetch(
                `https://api.addresszen.com/v1/autocomplete/addresses/${id}/usa?api_key=ak_m0yjsk0tinsf9Vw0oXvPcK02APYMa`
            ).then(response => response.json());
            if (response.result && typeof onAddressRetrieved === 'function') {
                onAddressRetrieved(response.result);
                setFocused(false);
                inputRef.current.blur();
                setAddress(
                    [response.result.line_1, response.result.line_2].join(' ')
                );
            }
        },
        [onAddressRetrieved]
    );

    const handleBlur = e => {
        if (!rootRef.current.contains(e.relatedTarget)) {
            setFocused(false);
        }
    };

    const handleFocus = () => {
        setFocused(true);
    };

    useEffect(() => {
        setValue(address);

        const doit = async address => {
            const controller = new AbortController();
            setSelected(false);
            if (abortControllerRef.current) {
                abortControllerRef.current.abort('new query request');
            }
            const signal = controller.signal;
            abortControllerRef.current = controller;
            const hits = await query(address, signal);
            setHits(hits);
        };

        if (address !== '') {
            doit(address);
        } else {
            setHits([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address]);

    const handleOutsideClick = useCallback(() => {
        if (rootRef?.current && !rootRef?.current.contains(event.target)) {
            setFocused(false);
        }
    }, [rootRef]);

    const handleKeydown = useCallback(
        e => {
            e = e || window.event;

            if (e.keyCode == '38') {
                const index = Math.max(1, selected - 1);
                setSelected(index);
                const el = document.getElementById(`item_${index}`);
                listRef.current.scrollTo(
                    0,
                    Math.max(
                        0,
                        el.offsetHeight * index - (250 - el.offsetHeight)
                    )
                );
            }
            if (e.keyCode == '40') {
                const index = Math.min(hits.length, selected + 1);
                setSelected(index);
                const el = document.getElementById(`item_${index}`);
                listRef.current.scrollTo(
                    0,
                    Math.max(
                        0,
                        el.offsetHeight * index - (250 - el.offsetHeight)
                    )
                );
            }

            if ((e.key === 'Enter' || e.keyCode === 13) && selected) {
                handleSelected(hits[selected - 1].id)();
            }
        },
        [selected, hits, handleSelected]
    );

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        document.addEventListener('keydown', handleKeydown);

        return function unmount() {
            document.removeEventListener('click', handleOutsideClick);
            document.removeEventListener('keydown', handleKeydown);
        };
    }, [handleOutsideClick, handleKeydown]);

    return (
        <div aria-haspopup="listbox" className={styles.root} ref={rootRef}>
            {/* eslint-disable-next-line jsx-a11y/role-supports-aria-props */}
            <input
                className={className}
                type="text"
                ref={inputRef}
                value={address}
                onChange={e => {
                    setAddress(e.target.value);
                }}
                data-1p-ignore
                data-lpignore="true"
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder="1234 Main St."
                autoComplete="none"
                aria-activedescendant={`item_${selected}`}
                aria-autocomplete="list"
                aria-controls="idpcaf2"
                aria-expanded="false"
                aria-owns="idpcaf2"
            />
            <div
                className={styles.listWrapper}
                style={{ display: focused ? 'block' : 'none' }}
            >
                <ul ref={listRef}>
                    {hits.length === 0 && <li>Start typing to find address</li>}
                    {hits.map((hit, i) => {
                        return (
                            <li
                                id={`item_${i + 1}`}
                                key={hit.id}
                                tabIndex="-1"
                                onClickCapture={handleSelected(hit.id)}
                                aria-selected={
                                    selected === i + 1 ? 'true' : 'false'
                                }
                                aria-setsize={hits.length}
                                aria-posinset={i + 1}
                            >
                                {hit.suggestion}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

AddressLookup.propTypes = {
    className: PropTypes.string,
    onAddressRetrieved: PropTypes.func,
    setValue: PropTypes.func,
};

export default AddressLookup;
