'use client';

import React from 'react';

import cx from 'clsx';
import PropTypes from 'prop-types';

import { BLOCKS, INLINES } from '@contentful/rich-text-types';

import Link from 'components/ui/Link';
import RichText from 'components/ui/RichText';
import Text from 'components/ui/Text';

import { blockRootProps } from 'utils';

import styles from './BlockRichText.module.scss';

const BlockRichText = ({
    anchorId,
    __typename,
    richText,
    flushTop,
    flushBottom,
}) => {
    const richTextOverrides = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                    <Text
                        as="p"
                        baseTheme="bodySmall"
                        themes={{ large: 'bodyMedium' }}
                        className={styles.bodyText}
                    >
                        {children}
                    </Text>
                );
            },
            [INLINES.HYPERLINK]: node => {
                return (
                    <Link className={styles.link} href={node.data.uri}>
                        {node.content[0].value}
                    </Link>
                );
            },
        },
    };

    return (
        <div
            {...blockRootProps(anchorId, __typename)}
            className={cx(styles.richTextBlock, {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
            })}
        >
            <RichText richText={richText} overrides={richTextOverrides} />
        </div>
    );
};

BlockRichText.propTypes = {
    anchorId: PropTypes.string,
    __typename: PropTypes.string,
    richText: PropTypes.object.isRequired,
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
};

export default BlockRichText;
