import React from 'react';

import { graphRequest } from 'services/contentful';

import BlockRichText from './BlockRichText';

const query = /* GraphQL */ `
    query getBlock($id: String!, $preview: Boolean!) {
        blockRichText(preview: $preview, id: $id) {
            anchorId
            __typename
            richText {
                json
            }
            flushTop
            flushBottom
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockRichText },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });
    return blockRichText;
}

const BlockRichTextServer = async ({ id }) => {
    const block = await fetchBlock(id);
    return <BlockRichText {...block} />;
};

export default BlockRichTextServer;
